// https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,700;0,900;1,700&display=swap
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUw-subset.woff2) format('woff2');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA-subset.woff2) format('woff2');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UA-subset.woff2) format('woff2');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA-subset.woff2) format('woff2');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/rubik/v12/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UA-subset.woff2) format('woff2');
}
.Rubik {
  font-family: 'Rubik';
}
