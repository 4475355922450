* {
  scroll-behavior: smooth;
}

/*
 * Deshabilita animaciones si la usuaria las tiene deshabilitadas
 */
@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto;
    animation: none !important;
  }
}

.inaccesible {
  outline: $red 1px solid;

  @extend .position-relative;

  &:focus::after {
    @extend .position-fixed;
    @extend .background-red;
    @extend .white;
    @extend .p-3;

    left: 0;
    bottom: 0;
    width: 100vw;
    font-size: 1rem;

    word-wrap: normal;
    line-break: auto;
    white-space: pre-line;

    content: attr(aria-label);
  }
}
