$snap-types: (none, mandatory, proximity);
$snap-directions: (y, x);
$snap-points: (start, end, center, none);

// TODO: Escribir un mixin
@each $snap-type in $snap-types {
  @each $snap-direction in $snap-directions {
    // El contenedor indica la dirección y obligatoriedad del snap
    .snap-#{$snap-type}-#{$snap-direction} {
      scroll-snap-points-#{$snap-direction}: repeat(100%);
      scroll-snap-destination: 0 0;
      @each $prefix in $vendor-prefixes {
        #{$prefix}scroll-snap-type: #{$snap-direction} #{$snap-type};
        #{$prefix}scroll-snap-type: #{$snap-type};
      }

      // Lxs hijxs directos se alinean al contenedor
      @each $snap-point in $snap-points {
        & > .snap-#{$snap-point} {
          scroll-snap-align: #{$snap-point};
        }
      }

      // Poder modificar el snap según el punto de corte
      @each $grid-breakpoint, $_ in $grid-breakpoints {
        @include media-breakpoint-up($grid-breakpoint) {
          @each $snap-point in $snap-points {
            & > .snap-#{$grid-breakpoint}-#{$snap-point} {
              scroll-snap-align: #{$snap-point};
            }
          }
        }
      }
    }

    @each $grid-breakpoint, $_ in $grid-breakpoints {
      @include media-breakpoint-up($grid-breakpoint) {
        .snap-#{$snap-type}-#{$grid-breakpoint}-#{$snap-direction} {
          // XXX: Esto está duplicado porque SCSS no nos deja extender
          // dentro de un @include.
          // @extend .snap-#{$snap-type}-#{$snap-direction};
          scroll-snap-points-#{$snap-direction}: repeat(100%);
          scroll-snap-destination: 0 0;
          @each $prefix in $vendor-prefixes {
            #{$prefix}scroll-snap-type: #{$snap-direction} #{$snap-type};
            #{$prefix}scroll-snap-type: #{$snap-type};
          }

          @each $snap-point in $snap-points {
            .snap-#{$snap-point} {
              scroll-snap-align: #{$snap-point};
            }
          }
        }
      }
    }
  }
}
