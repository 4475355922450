input[type="checkbox"].toggler {
  &:focus + label {
    outline: none;
  }
}

.share-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);

  @include vendor-prefix(transition, all 0.2s $bezier);

  a {
    border: $border-width solid transparent;

    &:focus,
    &:hover {
      text-decoration: none;
      border: $border-width solid $border-color;
    }
  }
}
