

$debug: false;
$vendor-prefixes: ("", "-webkit-", "-ms-", "-o-", "-moz-");
$bezier: cubic-bezier(0.75, 0, 0.25, 1);
/*font*/

$font-family-sans-serif: Rubik, sans-serif;

/*definicion de colores a partir del input de un _data/layout específico*/
$primary: #ea399c;
$secondary: #ff824a;
$third: #71dfc4;
$background: #fefefe;

$navbar-light-color: $primary;

$colors: (
  "transparent": transparent,
  "black": black,
  "primary": $primary,
  "secondary": $secondary,
  "third": $third,
  "background": $background
);

// Generar un tipo de botón para cada categoría
$theme-colors: (

"primario": orange,

"terciario": blue,

"inicial": cyan,

"universitario": purple,

"adultxs": magenta,

"primera-infancia": yellow,

"otro": red,

"secundario": green

);

$sizes: (
   80vh: 80vh,
   90vh: 90vh,
  100vh: 100vh
);


$opacities: (
  0: -100%,
  1: -80%,
  2: -60%,
  3: -40%,
  4: -20%,
  5: 0%
);

/*ancho máximo de caja de contenido*/
nav,
footer,
section {
  .inside {
    max-width: 1040px;
  }
}


/*
 * Redefinir variables de Boostrap acá.  Se las puede ver en
 * node_modules/bootstrap/scss/_variables.scss
 */

/*
 * XXX: Solo importar los archivos necesarios de bootstrap para
 * facilitar la reducción de CSS.  Por ahora pedimos todo para poder
 * empezar a trabajar en el HTML sin pensar en CSS.
 */
@import "bootstrap/scss/bootstrap";
@import "fork-awesome/scss/fork-awesome";
@import "accessibility";
@import "helpers";
@import "toggler";
@import "share_box";
@import "embed";
@import "snap";
@import "editor";
@import "menu";
@import "fonts";
@import "leaflet/dist/leaflet";

@if $debug {
  /*
   * Mostrar un borde alrededor de todos los elementos para poder
   * trabajar.
   */
  * { outline: 1px solid pink; }
}
